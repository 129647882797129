import { Component } from '@angular/core';
import {NgOptimizedImage} from '@angular/common';

@Component({
  selector: 'app-splash-icon',
  standalone: true,
  imports: [
    NgOptimizedImage,
  ],
  templateUrl: './splash-icon.component.html',
  styleUrl: './splash-icon.component.scss'
})
export class SplashIconComponent {

}
